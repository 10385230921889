// data
const personData = [
  {
    name: "UsernameTaken",
    avatar:
      "https://pbs.twimg.com/profile_images/1507868663386456068/cbXpCOWh_400x400.jpg",
    discord: "url",
    twitter: "url",
    quotePl:
      "podążaj za białym królikiem. Alicja wiedziała, że niebezpieczeństwo może stać na jej drodze, ale kto przejmowałby się niebezpieczeństwem w obliczu fantastycznej przygody.",
    quoteEn:
      "podążaj za białym królikiem. Alicja wiedziała, że niebezpieczeństwo może stać na jej drodze, ale kto przejmowałby się niebezpieczeństwem w obliczu fantastycznej przygody.",
    projects: [
      "nazwa",
      "nazwa 2",
      "nazwa",
      "nazwa 2",
      "nazwa",
      "nazwa 2",
      "nazwa",
      "nazwa 2",
    ],
    location: "Warszawa",
    numerologicSign: "7",
  },
  {
    name: "Osoba nr 2",
    avatar:
      "https://pbs.twimg.com/profile_images/1507868663386456068/cbXpCOWh_400x400.jpg",
    discord: "url",
    twitter: "url",
    quotePl: "text",
    quoteEn: "text",
    projects: ["nazwa", "nazwa 2"],
    location: "text",
    numerologicSign: "text",
  },
  {
    name: "Osoba nr 2",
    avatar:
      "https://pbs.twimg.com/profile_images/1507868663386456068/cbXpCOWh_400x400.jpg",
    discord: "url",
    twitter: "url",
    quotePl: "text",
    quoteEn: "text",
    projects: ["nazwa", "nazwa 2"],
    location: "text",
    numerologicSign: "text",
  },
  {
    name: "Osoba nr 2",
    avatar:
      "https://pbs.twimg.com/profile_images/1507868663386456068/cbXpCOWh_400x400.jpg",
    discord: "url",
    twitter: "url",
    quotePl: "text",
    quoteEn: "text",
    projects: ["nazwa", "nazwa 2"],
    location: "text",
    numerologicSign: "text",
  },
  {
    name: "Osoba nr 2",
    avatar:
      "https://pbs.twimg.com/profile_images/1507868663386456068/cbXpCOWh_400x400.jpg",
    discord: "url",
    twitter: "url",
    quotePl: "text",
    quoteEn: "text",
    projects: ["nazwa", "nazwa 2"],
    location: "text",
    numerologicSign: "text",
  },
];

export default personData;
