import React, { useState } from "react";
import styled from "styled-components";
import { StaticImage } from "gatsby-plugin-image";
import colors from "src/assets/styles/colors.js";
import star from "src/assets/svg/star_half-black.svg";
import distances from "src/assets/styles/distances.js";
import typographySizes from "src/assets/styles/typographySizes.js";
import mediaQuery from "src/assets/styles/mediaQuery.js";
import Paragraph from "src/components/global/Paragraph.js";
import ContextConsumer from "src/layouts/Context.js";
import pentagon from "src/assets/svg/pentagon.svg";
import PentagonOutline from "src/assets/svg/pentagon-outline.inline.svg";
import Discord from "src/assets/svg/discord.inline.svg";
import Twitter from "src/assets/svg/twitter.inline.svg";
import starPos from "src/assets/svg/star.svg";
import Arrow from "src/assets/svg/arrow.inline.svg";

const Wrapper = styled.div`
  width: calc((100vw - ${distances.pageMarginBig * 2}rem) * 0.33 - 20rem);

  position: relative;
  margin-right: 30rem;
  :last-of-type {
    margin-right: ${distances.pageMarginBig}rem;
  }
  @media (max-width: ${mediaQuery.desktop}) {
    width: 40vw;
  }
  @media (max-width: ${mediaQuery.tablet}) {
    width: calc(100% - 6rem);
    margin-right: 0rem;
    margin-bottom: 30rem;
  }
`;

const CardWrapper = styled.div`
  background-color: ${colors.white};
  position: relative;
  border: solid 3rem ${colors.dark};
  height: 100%;
  width: 100%;
  padding-bottom: 30rem;
`;

const Shadow = styled.div`
  background-image: linear-gradient(
    to right bottom,
    #d563ff,
    #d688ff,
    #d9a7ff,
    #e0c3ff,
    #eadeff,
    #dbd2ee,
    #ccc7dc,
    #bebbcb,
    #908ba5,
    #645e80,
    #39345d,
    #0d0e3b
  );
  width: calc(100% + 20rem);
  height: calc(100% + 20rem);
  position: absolute;
  left: -10rem;
  top: -10rem;
  filter: blur(10rem);
  opacity: 0.7;
`;
const ImgWrapper = styled.div`
  height: 100%;
  position: absolute !important;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 0;

  object-fit: cover absolute !important;
  div {
    height: 100%;
    width: 100%;
  }
`;

const TopBar = styled.div`
  height: 55rem;
  width: 100%;

  position: relative;
  border-bottom: solid 3rem ${colors.dark};
  ::after {
    content: "";
    position: absolute;
    bottom: -1.5rem;
    right: -3rem;
    background-image: url(${star});
    height: 45rem;
    width: 69rem;
    transform: translateY(50%);
    z-index: 2;
  }
  ::before {
    content: "";
    position: absolute;
    bottom: -1.5rem;
    left: -3rem;
    background-image: url(${star});
    height: 45rem;
    width: 69rem;
    transform: translateY(50%) rotate(180deg);
    z-index: 2;
  }
`;

const TopInfo = styled.div`
  width: 100%;
  align-items: center;
  display: flex;
  flex-direction: column;
`;

const AvatarWrapper = styled.div`
  margin-top: -40rem;
  position: relative;
`;

const Avatar = styled.div`
  height: 88rem;
  width: 88rem;
  mask-image: url(${pentagon});
  background-color: ${colors.white};
  mask-repeat: no-repeat;
  position: relative;
  img {
    object-fit: cover;
    height: 88rem;
    width: 88rem;
  }
`;

const StyledPentagoOutline = styled(PentagonOutline)`
  position: absolute;
  top: 0;
  z-index: 10;
  left: 50%;
  height: 88rem;
  /* width: 88rem; */
  transform: translateX(-50%);
`;

const Name = styled.h2`
  font-size: ${typographySizes.s}rem;
  font-weight: 700;
  margin-bottom: 5rem;
  margin-top: 10rem;
`;

const SocialWrapper = styled.div`
  display: flex;
`;

const StyledLink = styled.a`
  padding: 10rem;
  transition: 0.5s cubic-bezier(0.65, 0, 0.35, 1);

  :hover {
    transform: translateY(-5rem);
    transition: 0.5s cubic-bezier(0.65, 0, 0.35, 1);
  }
`;

const InfoWrapper = styled.div`
  border-bottom: solid 3rem ${colors.dark};
  width: calc(100% - 60rem);
  margin-left: 30rem;
`;

const InfoTopWrapper = styled.div`
  padding: 15rem 0 5rem 0;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const InfoTitle = styled.h3`
  font-size: ${typographySizes.m}rem;
  font-family: "DM Serif Display", serif;
`;

const InfoArrow = styled(Arrow)`
  transition: 0.5s cubic-bezier(0.65, 0, 0.35, 1);
  &.open {
    transform: rotate(180deg);
    transition: 0.5s cubic-bezier(0.65, 0, 0.35, 1);
  }
`;

const BottomWrapper = styled.div`
  height: ${(props) => (props.open ? 120 : 0)}rem;
  overflow-y: scroll;
  transition: 0.5s cubic-bezier(0.65, 0, 0.35, 1);
  margin-bottom: 10rem;
  &.projects {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
  }
  /* width */
  ::-webkit-scrollbar {
    width: 3rem;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #eaeaea;

    cursor: pointer;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    cursor: pointer;
    background: linear-gradient(
      0deg,
      rgba(100, 0, 179, 1) 0%,
      rgba(134, 184, 255, 1) 51%,
      rgba(211, 90, 255, 1) 100%
    );
    /* background: yellow; */
    width: 3rem;
    border-top: 5rem ${colors.white} solid;

    /* border-bottom: 5rem ${colors.white} solid; */
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: ${colors.dark};
    cursor: pointer;
  }

  @media (max-width: ${mediaQuery.tablet}) {
    height: ${(props) => (props.open ? 150 : 0)}rem;
  }
`;

const StyledParagraph = styled(Paragraph)`
  width: calc(100% - 20rem);
  padding-bottom: 30rem;
  font-style: italic;
`;

const ProjectPos = styled.p`
  font-size: ${typographySizes.s}rem;
  font-family: "DM Serif Display", serif;
  /* margin: 10rem 0; */
  width: calc(50% - 30rem);
  position: relative;

  height: 50rem;
  line-height: 50rem;
  margin-left: 30rem;
  ::after {
    content: "";
    height: 20rem;
    width: 20rem;
    background-image: url(${starPos});
    position: absolute;
    top: 50%;
    left: -10rem;
    transform: translate(-100%, -50%);
    background-repeat: no-repeat;
  }
`;

const BottomInfo = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 50rem 30rem 0 30rem;
`;

const BottomInfoText = styled.p`
  font-size: 12rem;
  span {
    font-size: inherit;
    font-weight: 700;
    margin-right: 10rem;
    display: inline-block;
  }
`;

const Card = ({
  name,
  projects,
  avatar,
  twitter,
  discord,
  quotePl,
  quoteEn,
  location,
  numerologicSign,
}) => {
  const [openQuote, setOpenQuote] = useState(false);
  const [openProjects, setOpenProjects] = useState(false);

  const openQuoteContainer = () => {
    setOpenProjects(openProjects && false);
    setOpenQuote(openQuote ? false : true);
  };

  const openProjectsContainer = () => {
    setOpenQuote(openQuote && false);
    setOpenProjects(openProjects ? false : true);
  };

  return (
    <ContextConsumer>
      {({ data, set }) => (
        <Wrapper>
          <Shadow />
          <CardWrapper>
            <TopBar>
              {" "}
              <ImgWrapper>
                <StaticImage
                  className="img"
                  objectFit="cover"
                  src="../assets/images/person-bg.png"
                  placeholder="blurred"
                  alt="zdjęcie w tle"
                />
              </ImgWrapper>
            </TopBar>
            <TopInfo>
              <AvatarWrapper>
                <Avatar>
                  <img src={avatar} />
                </Avatar>{" "}
                <StyledPentagoOutline />
              </AvatarWrapper>

              <Name>{name}</Name>
              <SocialWrapper>
                <StyledLink target="_blank" href={discord}>
                  <Discord />
                </StyledLink>
                <StyledLink target="_blank" href={twitter}>
                  <Twitter />
                </StyledLink>
              </SocialWrapper>
            </TopInfo>
            <InfoWrapper>
              <InfoTopWrapper onClick={openQuoteContainer}>
                <InfoTitle>Quote</InfoTitle>{" "}
                <InfoArrow className={openQuote && "open"} />
              </InfoTopWrapper>
              <BottomWrapper
                open={openQuote}
                onMouseEnter={() => set({ boxHover: true })}
                onMouseLeave={() => set({ boxHover: false })}
              >
                <StyledParagraph>
                  “{quotePl}”
                  <br />
                  - - - - - - - - - -
                  <br />“{quoteEn}”
                </StyledParagraph>
              </BottomWrapper>
            </InfoWrapper>
            <InfoWrapper>
              <InfoTopWrapper onClick={openProjectsContainer}>
                <InfoTitle>Featured projects</InfoTitle>{" "}
                <InfoArrow className={openProjects && "open"} />
              </InfoTopWrapper>
              <BottomWrapper
                open={openProjects}
                onMouseEnter={() => set({ boxHover: true })}
                onMouseLeave={() => set({ boxHover: false })}
                className="projects"
              >
                {projects.map((project) => (
                  <ProjectPos>{project}</ProjectPos>
                ))}
              </BottomWrapper>
            </InfoWrapper>
            <BottomInfo>
              <BottomInfoText>
                {" "}
                <span>Location:</span> {location}
              </BottomInfoText>
              <BottomInfoText>
                {" "}
                <span>Numerologic sign:</span> {numerologicSign}
              </BottomInfoText>
            </BottomInfo>
          </CardWrapper>
        </Wrapper>
      )}
    </ContextConsumer>
  );
};

export default Card;
